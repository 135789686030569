// firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBSekvsX_cPj-5frWUUce8xY5t-266sbEU",
    authDomain: "speedyhill-site.firebaseapp.com",
    databaseURL: "https://speedyhill-site.firebaseio.com",
    projectId: "speedyhill-site",
    storageBucket: "speedyhill-site.appspot.com",
    messagingSenderId: "461771606177",
    appId: "1:461771606177:web:b505aa038a67407de7c8e0",
    measurementId: "G-RBHYFX6054"
};

// initialize firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();